<template>
  <div class="home">
    <h1>Logging out...</h1>
  </div>
</template>

<script>
import BackendModel from "../../models/BackendModel"
import axios from 'axios'

export default {
  name: 'Logout',
  components: {
  },
  async mounted() {
    console.log("Mounted")
    const utils = new BackendModel()
    await axios.post(utils.getBaseUrl() + "/Api/service/backoffice/logout", {"backoffice_session_id" : utils.getBackofficeSessionId()})
    await this.$router.push({"path" : "/backoffice"})
  }
}
</script>